import { isLocal } from '@/utils';
import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';

export const baseURL = process.env.VUE_APP_ROOT_API;
const axiosInstance = axios.create({
  withCredentials: true,
  baseURL: process.env.VUE_APP_ROOT_API,
});

if (isLocal) {
  axiosInstance.defaults.params = {};
  axiosInstance.defaults.params.local = 'true';
}

axiosInstance.interceptors.response.use((response) => response,
  async (error) => {
    const originalRequest = error.config;
    if (error.response.status === 401 && !originalRequest.retryReq) {
      originalRequest.retryReq = true;
      try {
        const response: AxiosResponse<any> = await axios.get(`${process.env.VUE_APP_ROOT_API}/users/refresh${isLocal ? '?local=true' : ''}`, { withCredentials: true });
        localStorage.token = response.data?.token;
        return axiosInstance.request(originalRequest);
      } catch (e) {
        if (window.location.pathname !== '/login') {
          window.location.href = '/login';
        }
      }
    }
    return Promise.reject(error);
  });

axiosInstance.interceptors.request.use(
  async (config: AxiosRequestConfig) => {
    const accessToken = localStorage.token || '';
    config.headers.Authorization = `Bearer ${accessToken}`; // eslint-disable-line no-param-reassign
    return config;
  },
  (error) => Promise.reject(error),
);

export default axiosInstance;
