import { renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, withModifiers as _withModifiers, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, resolveDynamicComponent as _resolveDynamicComponent, mergeProps as _mergeProps, createBlock as _createBlock, toHandlerKey as _toHandlerKey, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = { class: "ui-table__container" }
const _hoisted_2 = {
  key: 0,
  class: "ui-table__selected-bar"
}
const _hoisted_3 = { class: "ui-table" }
const _hoisted_4 = { key: 0 }
const _hoisted_5 = ["width"]
const _hoisted_6 = { class: "ui-table__th-inner" }
const _hoisted_7 = { key: 1 }
const _hoisted_8 = ["onClick"]
const _hoisted_9 = { key: 0 }
const _hoisted_10 = { key: 0 }
const _hoisted_11 = { key: 1 }
const _hoisted_12 = ["width", "innerHTML"]
const _hoisted_13 = {
  key: 1,
  style: {"width":"3rem"}
}
const _hoisted_14 = { key: 0 }
const _hoisted_15 = ["colspan"]
const _hoisted_16 = { key: 1 }
const _hoisted_17 = ["colspan"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UiCheckbox = _resolveComponent("UiCheckbox")!
  const _component_UiMultiSelector = _resolveComponent("UiMultiSelector")!
  const _component_paginate = _resolveComponent("paginate")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.selectedRows.length && _ctx.$slots['select-bar'])
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _renderSlot(_ctx.$slots, "select-bar")
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("table", _hoisted_3, [
      _createElementVNode("thead", null, [
        _createElementVNode("tr", null, [
          (_ctx.showIndex)
            ? (_openBlock(), _createElementBlock("th", _hoisted_4, "№"))
            : _createCommentVNode("", true),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.cols, (col) => {
            return (_openBlock(), _createElementBlock("th", {
              width: col.width,
              key: `${col.field}_${col.label}`
            }, [
              _createElementVNode("div", _hoisted_6, [
                (col.slideLeft)
                  ? (_openBlock(), _createElementBlock("button", {
                      key: 0,
                      class: "ui-table__slide ui-table__slide--left",
                      onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.$emit('change:columnSlideIndex', -1)), ["stop"]))
                    }))
                  : _createCommentVNode("", true),
                _createElementVNode("span", null, _toDisplayString(col.label), 1),
                (col.slideRigth)
                  ? (_openBlock(), _createElementBlock("button", {
                      key: 1,
                      class: "ui-table__slide ui-table__slide--right",
                      onClick: _cache[1] || (_cache[1] = _withModifiers(($event: any) => (_ctx.$emit('change:columnSlideIndex', 1)), ["stop"]))
                    }))
                  : _createCommentVNode("", true)
              ])
            ], 8, _hoisted_5))
          }), 128)),
          (_ctx.useCheckboxes)
            ? (_openBlock(), _createElementBlock("th", _hoisted_7, [
                _createVNode(_component_UiCheckbox, {
                  checkAll: "",
                  modelValue: [],
                  value: _ctx.rows,
                  "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('update:selectedRows', $event))),
                  onClick: _cache[3] || (_cache[3] = _withModifiers(() => {}, ["stop"]))
                }, null, 8, ["value"])
              ]))
            : _createCommentVNode("", true)
        ])
      ]),
      _createElementVNode("tbody", null, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.rows, (row, i) => {
          return (_openBlock(), _createElementBlock("tr", {
            onClick: ($event: any) => (_ctx.$emit('rowClick', row))
          }, [
            (_ctx.showIndex)
              ? (_openBlock(), _createElementBlock("td", _hoisted_9, _toDisplayString(i + 1), 1))
              : _createCommentVNode("", true),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.cols, (col) => {
              return (_openBlock(), _createElementBlock(_Fragment, null, [
                (col.component)
                  ? (_openBlock(), _createElementBlock("td", _hoisted_10, [
                      (col.component(row).value)
                        ? (_openBlock(), _createBlock(_resolveDynamicComponent(col.component(row).name), _mergeProps({
                            key: 0,
                            innerHTML: col.component(row).value
                          }, (({name, value, ...props}) => props)(col.component(row))), null, 16, ["innerHTML"]))
                        : (_openBlock(), _createBlock(_resolveDynamicComponent(col.component(row).name), _mergeProps({ key: 1 }, (({name, ...props}) => props)(col.component(row)), {
                            [_toHandlerKey(col.emit)]: (value) => _ctx.$emit(col.emit, value)
                          }), null, 16))
                    ]))
                  : (col.select)
                    ? (_openBlock(), _createElementBlock("td", _hoisted_11, [
                        _createVNode(_component_UiMultiSelector, {
                          modelValue: row[col.field][col.field2] || row[col.field],
                          width: col.width,
                          "onUpdate:modelValue": (value) => _ctx.$emit(col.emit, { id: row.id, field: col.field, value }),
                          options: col.options
                        }, null, 8, ["modelValue", "width", "onUpdate:modelValue", "options"])
                      ]))
                    : (_openBlock(), _createElementBlock("td", {
                        key: 2,
                        width: col.width,
                        style: _normalizeStyle(col.style),
                        innerHTML: col.display ? col.display(row) : row[col.field] ? row[col.field] : col.defaultValue
                      }, null, 12, _hoisted_12))
              ], 64))
            }), 256)),
            (_ctx.useCheckboxes)
              ? (_openBlock(), _createElementBlock("td", _hoisted_13, [
                  _createVNode(_component_UiCheckbox, {
                    modelValue: _ctx.selectedRows,
                    "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => (_ctx.$emit('update:selectedRows', $event))),
                    value: row,
                    onClick: _cache[5] || (_cache[5] = _withModifiers(() => {}, ["stop"]))
                  }, null, 8, ["modelValue", "value"])
                ]))
              : _createCommentVNode("", true)
          ], 8, _hoisted_8))
        }), 256)),
        (!_ctx.rows.length)
          ? (_openBlock(), _createElementBlock("tr", _hoisted_14, [
              _createElementVNode("td", {
                colspan: _ctx.cols.length + (_ctx.useCheckboxes ? 1 : 0),
                style: {"text-align":"center"}
              }, _toDisplayString(_ctx.emptyBannerText), 9, _hoisted_15)
            ]))
          : _createCommentVNode("", true),
        (_ctx.pagination && _ctx.pagination.pages > 1 && !_ctx.pagination.fetching)
          ? (_openBlock(), _createElementBlock("tr", _hoisted_16, [
              _createElementVNode("td", {
                class: "paginate-bar",
                colspan: _ctx.cols.length + (_ctx.useCheckboxes ? 1 : 0)
              }, [
                _createVNode(_component_paginate, {
                  modelValue: _ctx.pagination.currentPage,
                  pages: _ctx.pagination.pages,
                  "range-size": 10,
                  "active-color": "#028a8f",
                  "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => (_ctx.$emit('changePage', $event)))
                }, null, 8, ["modelValue", "pages"])
              ], 8, _hoisted_17)
            ]))
          : _createCommentVNode("", true)
      ])
    ])
  ]))
}