import { AxiosPromise } from 'axios';
import { NalogRegime } from '@/types/NalogRegime';
import axios from '../axios';

export interface NalogRegimeApi {
  findAll(): AxiosPromise<NalogRegime[]>
  loadFile(id: FormData): AxiosPromise<void>
  update(type: NalogRegime): AxiosPromise<void>
}

const findAll: NalogRegimeApi['findAll'] = () => axios.get('/nalogregime/findall');

const loadFile: NalogRegimeApi['loadFile'] = (file) => axios.post('/nalogregime/loadfile', file);

const update: NalogRegimeApi['update'] = (type) => axios.post('/nalogregime/save', type);

const nalogRegime: NalogRegimeApi = {
  findAll,
  loadFile,
  update,
};

export default nalogRegime;
