import { createApp } from 'vue';
import App from '@/App.vue';
import router from '@/router';
import store from '@/store';
import UIComponents from '@/components/UI/UIComponents';
import i18n from './i18n';
import '@hennge/vue3-pagination/dist/vue3-pagination.css';
import '@/assets/styles/main.styl';
import 'vue-select/dist/vue-select.css';

function initApp() {
  const app = createApp(App)
    .use(store)
    .use(router)
    .use(i18n);

  UIComponents.forEach((component) => app.component(component.name, component));

  app.mount('#app');
}

const token = localStorage.getItem('token');

(async () => {
  if (token) {
    await store.dispatch('auth/SET_TOKENS', {
      token,
    });
    try {
      await store.dispatch('auth/GET_USER_INFO');
      initApp();
    } catch (e) {
      if (window.location.href !== '/login') {
        await router.replace({ name: 'Login' });
        initApp();
      }
    }
  } else if (window.location.href.includes('privacy-policy')) {
    initApp();
  } else {
    await store.dispatch('auth/LOGOUT');
    await router.replace({ name: 'Login' });
    initApp();
  }
})();
