import { MutationTree } from 'vuex';
import { LoadingStatus } from '@/types/Simple';
import { ObjectOfTrade } from '@/types/ObjectOfTrade';
import { State } from './state';

// eslint-disable-next-line no-shadow
export enum MutationTypes {
  SET_OBJECTS = 'SET_OBJECTS',
  SET_STATUS = 'SET_STATUS'
}

export type Mutations<S = State> = {
  [MutationTypes.SET_OBJECTS](state: S, objects: ObjectOfTrade[]): void;
  [MutationTypes.SET_STATUS](state: S, status: LoadingStatus): void;
};

export const mutations: MutationTree<State> & Mutations = {
  [MutationTypes.SET_OBJECTS](state, objects) {
    state.objects = objects;
  },
  [MutationTypes.SET_STATUS](state, status) {
    state.loadingStatus = status;
  },
};
