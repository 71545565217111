// eslint-disable-next-line import/prefer-default-export
import { AxiosResponse } from 'axios';

export const badgeProps = (status: string): {badgeColor: string, text: string} => {
  let badgeColor;
  let text;
  switch (status) {
    case 'LIQUIDATED':
      badgeColor = '#000';
      text = 'Ликвидирован';
      break;
    case 'NOTPROCESSED':
      badgeColor = '#ff9d7e';
      text = 'Не обработан';
      break;
    case 'DUPLICATE':
      badgeColor = '#FF0000';
      text = 'Дубль';
      break;
    default:
      badgeColor = '#7eb9ff';
      text = 'Обработан';
  }
  return {
    badgeColor,
    text,
  };
};

export function numberWithSpaces(x: string | number): string {
  const parts = x.toString().split('.');
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
  return parts.join('.');
}

export function downloadFile(filename: string) {
  // eslint-disable-next-line func-names
  return function (response: AxiosResponse): void {
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', filename);
    document.body.appendChild(link);
    link.click();
  };
}
export function downloadFileDynName(response: AxiosResponse) {
  const fileName = response.headers['content-disposition'].split('filename=')[1];
  if (window.navigator && window.navigator.msSaveOrOpenBlob) { // IE variant
    window.navigator.msSaveOrOpenBlob(new Blob([response.data],
      { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }),
    fileName);
  } else {
    const url = window.URL.createObjectURL(new Blob([response.data],
      { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download',
      response.headers['content-disposition'].split('filename=')[1]);
    document.body.appendChild(link);
    link.click();
  }
}

export const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|BB|PlayBook|IEMobile|Windows Phone|Kindle|Silk|Opera Mini/i.test(navigator.userAgent);

type Roles = 'ADMIN' | 'CA' | 'TNO' | 'USER'
export const translateRoles = (role: Roles) => {
  const roles: { [prop: string]: string } = {
    ADMIN: 'Администратор',
    CA: 'Просмотр ЦА',
    TNO: 'ТНО',
    USER: 'Пользователь МП',
  };

  return roles[role] ?? 'Пользователь';
};

export const isLocal = window.location.host.includes('localhost');

export const parseDate = (date: string | undefined): string | undefined => {
  if (!date) return undefined;
  const parsedDate = date.split(/[-T]/);
  return `${parsedDate[2]}.${parsedDate[1]}.${parsedDate[0]}`;
};
