import {
  createRouter,
  createWebHistory, NavigationGuardNext,
  RouteLocationNormalized,
  RouteRecordRaw,
} from 'vue-router';
import store from '@/store';

const Home = () => import('../views/index.vue');
const Login = () => import('../views/login.vue');
const MapPage = () => import('../views/map-page/index.vue');
const Registry = () => import('../views/registry.vue');
const RegistryObjectsOfTrade = () => import('../views/registry/trade-objects.vue');
const RegistryObjectsOfTradeIndex = () => import('../views/registry/trade-objects/index.vue');
const RegistryObjectsOfTradeObjectId = () => import('../views/registry/trade-objects/_objectId.vue');
const Ugns = () => import('../views/registry/ugns.vue');
const UgnsIndex = () => import('../views/registry/ugns/index.vue');
const UgnsUgnsId = () => import('../views/registry/ugns/_ugnsId.vue');
const NalogPayers = () => import('../views/registry/nalog-payers.vue');
const NalogPayersIndex = () => import('../views/registry/nalog-payers/index.vue');
const TaxPayers = () => import('../views/registry/tax-payers.vue');
const TaxPayersIndex = () => import('../views/registry/tax-payers/index.vue');
const TaxPayersNew = () => import('../views/registry/tax-payers/new.vue');
const TaxPayersId = () => import('../views/registry/tax-payers/_payerId.vue');
const WorkerId = () => import('../views/registry/worker/_workerId.vue');
const ReportsLayout = () => import('../views/reports.vue');
const ReportsList = () => import('../views/reports/index.vue');
const ReportsDetail = () => import('../views/reports/_report.vue');
const MarketsAndMalls = () => import('../views/markets-and-malls.vue');
const MarketsAndMallsIndex = () => import('../views/markets-and-malls/index.vue');
const MarketsAndMallsMarkets = () => import('../views/markets-and-malls/markets.vue');
const MarketsAndMallsMalls = () => import('../views/markets-and-malls/malls.vue');
const MarketsAndMallsMallsIndex = () => import('../views/markets-and-malls/malls/index.vue');
const MarketsAndMallsMallsMallId = () => import('../views/markets-and-malls/malls/_mall-id.vue');
const MarketsAndMallsMallsMallIdIndex = () => import('../views/markets-and-malls/malls/mall-id/index.vue');
const MarketsAndMallsMallsMallIdTaxpayerId = () => import('../views/markets-and-malls/malls/mall-id/_taxpayer-id.vue');
const MarketsAndMallsMarketIndex = () => import('../views/markets-and-malls/markets/index.vue');
const MarketsAndMallsMarketsMarketId = () => import('../views/markets-and-malls/markets/_market-id.vue');
const MarketsAndMallsMarketsMarketIdIndex = () => import('../views/markets-and-malls/markets/market-id/index.vue');
const MarketsAndMallsMarketsMarketIdTaxpayerId = () => import('../views/markets-and-malls/markets/market-id/_taxpayer-id.vue');
const AccessControl = () => import('../views/admin-panel/access-control.vue');
const RolesTable = () => import('../views/admin-panel/roles-table.vue');
const PrivacyPolicy = () => import('../views/privacy-policy.vue');

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    component: Home,
    // beforeEnter: ifIsAuth,
    children: [
      {
        path: '',
        name: 'home-map',
        component: MapPage,
      },
      {
        path: '/access-control',
        name: 'access-control',
        component: AccessControl,
      },
      {
        path: '/roles-table',
        name: 'roles-table',
        component: RolesTable,
      },
      {
        path: 'registry',
        name: 'registry',
        component: Registry,
        children: [
          {
            path: 'trade-objects',
            name: 'registry-trade-objects',
            component: RegistryObjectsOfTrade,
            children: [
              {
                path: '',
                name: 'registry-trade-objects-index',
                component: RegistryObjectsOfTradeIndex,
              },
              {
                path: ':objectId(\\d+)',
                name: 'registry-trade-objects-object-id',
                component: RegistryObjectsOfTradeObjectId,
              },
            ],
          },
          {
            path: 'ugns',
            name: 'registrys-ugns-authorities',
            component: Ugns,
            children: [
              {
                path: '',
                name: 'registry-ugns-index',
                component: UgnsIndex,
              },
              {
                path: ':ugnsId(\\d+)',
                name: 'registry-ugns-id',
                component: UgnsUgnsId,
              },
            ],
          },
          {
            path: 'nalogpayers',
            name: 'registrys-nalogpayers-authorities',
            component: NalogPayers,
            children: [
              {
                path: '',
                name: 'registry-nalogpayers-index',
                component: NalogPayersIndex,
              },
              {
                path: ':payerId',
                name: 'registry-nalogpayers-object-id',
                component: TaxPayersId,
              },
            ],
          },
          {
            path: 'worker/:workerId(\\d+)',
            name: 'registry-worker-id',
            component: WorkerId,
          },
          {
            path: 'tax-payers',
            name: 'registry-tax-payers',
            component: TaxPayers,
            children: [
              {
                path: '',
                name: 'registry-tax-payers-index',
                component: TaxPayersIndex,
                redirect: { name: 'registry-tax-payers-new' },
              },
              {
                path: ':payerId',
                name: 'registry-tax-payers-payer-id',
                component: TaxPayersId,
              },
              {
                path: 'new',
                name: 'registry-tax-payers-new',
                component: TaxPayersNew,
              },
            ],
          },
        ],
      },
      {
        path: '',
        component: Registry,
        children: [
          {
            path: 'tno-dashboard',
            name: 'tno-dashboard',
            component: UgnsUgnsId,
          },
          {
            path: 'tno-dashboard/workers/:workerId(\\d+)',
            name: 'tno-dashboard-worker-id',
            component: WorkerId,
          },
          {
            path: 'tno-dashboard/objects/:objectId(\\d+)',
            name: 'tno-dashboard-object-id',
            component: RegistryObjectsOfTradeObjectId,
          },
        ],
      },
      {
        path: 'reports',
        name: 'reports',
        component: ReportsLayout,
        children: [
          {
            path: '',
            name: 'reports-list',
            component: ReportsList,
          },
          {
            path: 'detail',
            name: 'reports-detail',
            component: ReportsDetail,
          },
        ],
      },
      {
        path: 'markets-and-malls',
        component: MarketsAndMalls,
        children: [
          {
            path: '',
            name: 'markets-and-malls',
            component: MarketsAndMallsIndex,
            redirect: { name: 'markets-and-malls-markets' },
          },
          {
            path: 'malls',
            component: MarketsAndMallsMalls,
            children: [
              {
                path: '',
                name: 'markets-and-malls-malls',
                component: MarketsAndMallsMallsIndex,
              },
              {
                path: ':mallId',
                component: MarketsAndMallsMallsMallId,
                children: [
                  {
                    path: '',
                    name: 'markets-and-malls-malls-mall-id',
                    component: MarketsAndMallsMallsMallIdIndex,
                  },
                  {
                    path: ':payerId',
                    name: 'markets-and-malls-malls-mall-id-taxpayer-id',
                    component: MarketsAndMallsMallsMallIdTaxpayerId,
                  },
                ],
              },
            ],
          },
          {
            path: 'markets',
            component: MarketsAndMallsMarkets,
            children: [
              {
                path: '',
                name: 'markets-and-malls-markets',
                component: MarketsAndMallsMarketIndex,
              },
              {
                path: ':marketId',
                component: MarketsAndMallsMarketsMarketId,
                children: [
                  {
                    path: '',
                    name: 'markets-and-malls-markets-market-id',
                    component: MarketsAndMallsMarketsMarketIdIndex,
                  },
                  {
                    path: ':payerId',
                    name: 'markets-and-malls-markets-market-id-taxpayer-id',
                    component: MarketsAndMallsMarketsMarketIdTaxpayerId,
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
  },
  {
    path: '/privacy-policy',
    name: 'PrivacyPolicy',
    component: PrivacyPolicy,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    let position = { left: 0, top: 0 };
    if (savedPosition) {
      position = savedPosition;
    }
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(position);
      }, 1000);
    });
  },
});

// TODO: вернуть, но ничего не ломая
// router.beforeEach((
//   to: RouteLocationNormalized,
//   from: RouteLocationNormalized,
//   next: NavigationGuardNext,
// ) => {
//   // ТНО заходит не в ЛК ТНО, то редирект
//   if (store.state.auth.isTno && (to.path.includes('/registy') || to.path === '/')) {
//     return next('/tno-dashboard');
//   }
//   if (store.state.auth.isCA && (to.name === 'home' || to.name === 'home-map')) {
//     return next('/registry/trade-objects');
//   }
//   return next();
// });

// router.beforeEach((
//   to: RouteLocationNormalized,
//   from: RouteLocationNormalized,
//   next: NavigationGuardNext,
// ) => {
//   store.commit('user/SET_FROM_ROUTE', from);
//   next();
// });

export default router;
