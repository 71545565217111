
import {
  computed, defineComponent, onBeforeUnmount, onMounted, PropType, ref,
} from 'vue';

export default defineComponent({
  name: 'UiSelector',
  props: {
    placeholder: { default: '', type: String },
    label: { default: '', type: String },
    modelValue: {
      default: null,
      type: Object as PropType<{ name: string, value: string | number } | undefined>,
      required: true,
    },
    options: { default: () => [], type: Array as PropType<{ name: string, value: string | number }[]> },
    useSearch: {
      type: Boolean,
      default: true,
    },
  },
  setup(props) {
    const searchValue = ref('');
    const showOptions = ref(false);

    const refSearch = ref<HTMLInputElement>();
    const refRoot = ref<HTMLElement>();

    const filteredOptions = computed(() => props.options.filter((option) => `${option?.name || ''}`.toLowerCase().includes(searchValue.value.toLowerCase())));

    const hideOptions = (e: any) => {
      showOptions.value = false;
    };
    onMounted(() => {
      document.body.addEventListener('click', hideOptions);
    });
    onBeforeUnmount(() => {
      document.body.removeEventListener('click', hideOptions);
    });
    return {
      refRoot, refSearch, searchValue, filteredOptions, showOptions,
    };
  },
});
