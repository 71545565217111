import { MutationTree } from 'vuex';
import { LoadingStatus } from '@/types/Simple';
import { Statistic } from '@/types/Statistic';
import { State } from './state';

// eslint-disable-next-line no-shadow
export enum MutationTypes {
  SET_DATA = 'SET_DATA',
  SET_STATUS = 'SET_STATUS'
}

export type Mutations<S = State> = {
  [MutationTypes.SET_DATA](state: S, data: Statistic): void;
  [MutationTypes.SET_STATUS](state: S, status: LoadingStatus): void;
};

export const mutations: MutationTree<State> & Mutations = {
  [MutationTypes.SET_DATA](state, data) {
    state.data = data;
  },
  [MutationTypes.SET_STATUS](state, status) {
    state.loadingStatus = status;
  },
};
