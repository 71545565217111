import { GetterTree } from 'vuex';
import { RootState } from '@/store/rootState';
import { ObjectOfTrade } from '@/types/ObjectOfTrade';
import { State } from './state';

export type Getters = {
  getObjectById(state: State): (id: string) => ObjectOfTrade | undefined
};

export const getters: GetterTree<State, RootState> & Getters = {
  getObjectById: (state) => (id: string) => state.objects?.find((elem) => `${elem.tradeObject.id}` === `${id}`),
  getObjectsByStatus: (state) => (status: string) => {
    if (!status) return state.objects;
    return state.objects?.filter((elem) => elem.tradeObject?.objectStatus?.toString() === status);
  },
};
