import { MutationTree } from 'vuex';
import { UgnsTno } from '@/types/Ugnstno';
import { LoadingStatus } from '@/types/Simple';
import { State } from './state';

// eslint-disable-next-line no-shadow
export enum MutationTypes {
  SET_UGNS = 'SET_UGNS',
  SET_STATUS = 'SET_STATUS'
}

export type Mutations<S = State> = {
  [MutationTypes.SET_UGNS](state: S, objects: UgnsTno[]): void;
  [MutationTypes.SET_STATUS](state: S, status: LoadingStatus): void;
};

export const mutations: MutationTree<State> & Mutations = {
  [MutationTypes.SET_UGNS](state, objects) {
    state.objects = objects;
  },
  [MutationTypes.SET_STATUS](state, status) {
    state.loadingStatus = status;
  },
};
