<template lang="pug">
Btn(type="danger-outline" fullWidth @click="$emit('removeUser', { id, ugnsId })") Удалить
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
  props: { id: { type: Number }, ugnsId: { type: Number, default: null } },
});
</script>
<style lang="stylus" scoped>
button {
  height 31px
  padding 5px 20px 5px
}
</style>
