<template lang="pug">
.change-status__wrapper
  .change-status__marker(@click="show()")
    span(class="ui-table__pointer" :style="{background: badgeColor}" @click="show()")
  template(v-if="open")
    .popunder__overlay(@click="close()")
    .popunder__wrapper
      .popunder__item(@click="close()")
        span(class="ui-table__pointer" :style="{background: badgeColor, marginRight: '1.5rem'}" )
        span {{text}}
      .popunder__item(@click="$emit('update:status', 'DUPLICATE'); close()")
        span(class="ui-table__pointer" style="background: #FF0000; margin-right: 1.5rem")
        span Дубль
</template>

<script>
export default {
  props: {
    badgeColor: {
      type: String,
      default: '',
    },
    text: {
      type: String,
      default: '',
    },
  },
  name: 'ChangeStatus',
  data() {
    return {
      open: false,
    };
  },
  methods: {
    close() {
      if (this.text !== 'Дубль') this.open = false;
    },
    show() {
      if (this.text !== 'Дубль' && !this.duplicateToggleRestrict) this.open = true;
    },
  },
  computed: {
    duplicateToggleRestrict() {
      return this.$store.state.auth.isCA || this.$route.name === 'tno-dashboard';
    },
  },
};
</script>

<style lang="stylus" scoped>
.change-status {
  &__wrapper {
    position: relative
  }
  &__marker {
    cursor pointer;
  }
}
.popunder {
  &__overlay {
    position fixed
    top 0
    left 0
    right 0
    bottom 0
  }
  &__wrapper {
    position: absolute
    z-index: 1;
    top: 100%;
    left: 0;
    white-space nowrap
    background #fff;
    margin-left: -2.5rem
    box-shadow: 2px 2px 10px rgba(20, 29, 37, 0.2);
    border-radius: 4px;
    overflow hidden
  }
  &__item {
    transition: background-color .3s
    background: #fff
    font-size: 2rem
    padding: 2.5rem
    display flex
    align-items center
    color: #000
    cursor pointer;

    &:hover {
      background: alpha(#B7B4B4, .5)
    }
  }
}
</style>
