import user, { UserApi } from '@/api/user';
import objectsOfTrade, { ObjectOfTradeApi } from '@/api/objectsOfTrade';
import ugnstno, { UgnstnoApi } from '@/api/ugnstno';
import statistic, { StatisticApi } from '@/api/statistic';
import activityType, { ActivityTypeApi } from '@/api/activityType';
import businessType, { BusinessTypeApi } from '@/api/businessType';
import nalogRegime, { NalogRegimeApi } from '@/api/nalogRegime';
import taxPayers, { TaxPayersApi } from '@/api/taxPayers';
import kkmModel, { KkmModelApi } from '@/api/kkmModel';
import reports, { ReportApi } from '@/api/reports';
import malls, { MallsApi } from '@/api/malls';
import markets, { MarketsApi } from '@/api/markets';
// eslint-disable-next-line import/named
import innerProblem, { InnerProblemApi } from '@/api/innerProblem';

interface Api {
  user: UserApi,
  objectsOfTrade: ObjectOfTradeApi,
  ugnstno: UgnstnoApi,
  statistic: StatisticApi,
  activityType: ActivityTypeApi,
  businessType: BusinessTypeApi,
  nalogRegime: NalogRegimeApi,
  taxPayers: TaxPayersApi,
  kkmModel: KkmModelApi,
  reports: ReportApi,
  malls: MallsApi,
  markets: MarketsApi,
  innerProblem: InnerProblemApi
}

const api: Api = {
  user,
  objectsOfTrade,
  ugnstno,
  statistic,
  activityType,
  businessType,
  nalogRegime,
  taxPayers,
  kkmModel,
  reports,
  malls,
  markets,
  innerProblem,
};

export default api;
