import { AxiosPromise } from 'axios';
import axios from '@/axios';
import { UgnsTno } from '@/types/Ugnstno';
import { UgnsTnoById, UgnsTnoWorker } from '@/types/UgnsTnoById';

export interface UgnstnoApi {
  findAll(params?: { withCA?: boolean }): AxiosPromise<UgnsTno[]>
  getById(id: string | string[]): AxiosPromise<UgnsTnoById>
  getInfoById(id: string | string[]): AxiosPromise<UgnsTno>
  getWorkersById(id: number): AxiosPromise<UgnsTnoWorker[]>
}

const findAll: UgnstnoApi['findAll'] = (params) => axios.get('/ugnstno/findall', { params });
const getById: UgnstnoApi['getById'] = (id) => axios.get(`/ugnstno/get/${id}`);
const getWorkersById: UgnstnoApi['getWorkersById'] = (id) => axios.get(`/ugnstno/${id}/workers`);
const getInfoById: UgnstnoApi['getInfoById'] = (id) => axios.get(`/ugnstno/${id}/info`);

const ugnstno: UgnstnoApi = {
  findAll,
  getById,
  getWorkersById,
  getInfoById,
};

export default ugnstno;
