/**
 * @usage:
 *
 *   <CheckBox label="Foo" value="foo" v-model="MySelectedValues" />
 *   <CheckBox label="Bar" value="bar" v-model="MySelectedValues" />
 *   <CheckBox label="Baz" value="baz" v-model="MySelectedValues" />
 *
 * data(){
 *    return {
 *      MySelectedValues: [],
 *    }
 *  }
 */
<template>
  <label class="wrapper flex items-center">
    {{label}}
    <input class="checkbox" type="checkbox" :checked="isChecked" :disabled="disabled" :value="value" @change="updateInput"/>
    <span class="checkmark"></span>
  </label>
</template>

<script>
export default {
  name: 'UiCheckbox',
  props: {
    value: { type: Object },
    modelValue: { default: '' },
    label: { type: String, default: '' },
    trueValue: { default: true },
    falseValue: { default: false },
    checkAll: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
  },
  computed: {
    isChecked() {
      if (this.modelValue instanceof Array) {
        return this.modelValue.includes(this.value);
      }
      // Note that `true-value` and `false-value` are camelCase in the JS
      return this.modelValue === this.trueValue;
    },
  },
  methods: {
    updateInput(event) {
      const isChecked = event.target.checked;
      if (this.checkAll) {
        if (isChecked) {
          this.$emit('change', this.value);
          this.$emit('update:modelValue', this.value);
        } else {
          this.$emit('change', []);
          this.$emit('update:modelValue', []);
        }
      } else if (this.modelValue instanceof Array) {
        const newValue = [...this.modelValue];
        if (isChecked) {
          newValue.push(this.value);
        } else {
          newValue.splice(newValue.indexOf(this.value), 1);
        }
        this.$emit('change', newValue);
        this.$emit('update:modelValue', newValue);
      } else {
        this.$emit('changeValue', isChecked ? this.trueValue : this.falseValue);
      }
    },
  },
};
</script>

<style lang="stylus" scoped>
/* Customize the label (the wrapper) */
.wrapper {
  display: flex;
  column-gap 10px;
  align-items center;
  position: relative;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-size: 2rem;
  flex: 0 0 auto;
}
/* Hide the browser's default checkbox */
.wrapper input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
/* Create a custom checkbox */
.checkmark {
  display block
  position: relative;
  height: 2rem;
  width: 2rem;
  background: #FFFFFF;
  border: 0.15rem solid #CAD0D5;
  box-sizing: border-box;
  border-radius: .2rem;
  transition: .08s;
}
/* On mouse-over, add a grey background color */
.wrapper:hover input ~ .checkmark {
  border: 0.15rem solid #CAD0D5;
}
/* When the checkbox is checked, add a blue background */
.wrapper input:checked ~ .checkmark {
  background: #FFFFFF;
  border: 0.15rem solid #028A8F;
  box-sizing: border-box;
  border-radius: 2px;
}
/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}
/* Show the checkmark when checked */
.wrapper input:checked ~ .checkmark:after {
  visibility: visible;
}
/* Style the checkmark/indicator */
.wrapper .checkmark:after {
  display: block;
  visibility: hidden;
  transition: .08s linear;
  left: .35rem;
  top: 0;
  width: 1rem;
  height: 1.4rem;
  border: solid #028a8f;
  border-width: 0 .3rem .3rem 0;
  transform: rotate(36deg);
}
.wrapper .checkmark:before {
  content ''
  display block
  position absolute
  left -1rem
  top -1rem
  width calc(100% + 2rem)
  height calc(100% + 2rem)
}
</style>
