import { createStore, createLogger } from 'vuex';

import auth from '@/store/auth';
import user from '@/store/user';
import ugns from '@/store/ugns';
import map from '@/store/map';
import registry from '@/store/registry';
import objectsOfTrade from '@/store/objectsOfTrade';
import taxPayers from '@/store/taxPayers';
import stats from '@/store/stats';

const debug = process.env.NODE_ENV !== 'production';
const plugins = debug ? [createLogger({})] : [];

const store = createStore({
  strict: true,
  plugins,
  modules: {
    user,
    auth,
    ugns,
    objectsOfTrade,
    taxPayers,
    stats,
    map,
    registry,
  },
});

export default store;
