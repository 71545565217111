import { AxiosPromise } from 'axios';
import { ActivityType } from '@/types/ActivityType';
import axios from '../axios';

export interface ActivityTypeApi {
  findAll(): AxiosPromise<ActivityType[]>
  loadFile(id: FormData): AxiosPromise<void>
  update(type: ActivityType): AxiosPromise<void>
}

const findAll: ActivityTypeApi['findAll'] = () => axios.get('/activitytype/findall');

const loadFile: ActivityTypeApi['loadFile'] = (file) => axios.post('/activitytype/loadfile', file);

const update: ActivityTypeApi['update'] = (type) => axios.post('/activitytype/save', type);

const activityType: ActivityTypeApi = {
  findAll,
  loadFile,
  update,
};

export default activityType;
