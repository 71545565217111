import { AxiosPromise } from 'axios';
import { Statistic, TnoRow } from '@/types/Statistic';
import axios from '../axios';

export interface StatisticApi {
  getForAllTno(): AxiosPromise<TnoRow[]>
  getForTnoById(id: string | string[]): AxiosPromise<Statistic>
  getInfo(): AxiosPromise<Statistic>
}

const getForAllTno: StatisticApi['getForAllTno'] = () => axios.get('/statistic/tno');

const getInfo: StatisticApi['getInfo'] = () => axios.get('/statistic/info');

const getForTnoById: StatisticApi['getForTnoById'] = (id) => axios.get(`/statistic/singleTno/${id}`);

const statistic: StatisticApi = {
  getForAllTno,
  getForTnoById,
  getInfo,
};

export default statistic;
