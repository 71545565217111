import { AxiosPromise } from 'axios';
import {
  ReportActivityRow,
  ReportBusinessTypeByActivityRow,
  ReportInspectorRow,
  ReportKkmByActivityRow,
  ReportKkmResponse,
  // ReportRegimeRow,
  ReportRegimeUgnsRow,
  ReportTerritoryRow,
} from '@/types/Reports';
import { downloadFileDynName } from '@/utils';
import { Pageable, PaginationParams } from '@/types/Common';
import axios from '../axios';

export interface ReportApi {

  getActivities(params?: Record<string, unknown>): AxiosPromise<ReportActivityRow[]>
  getInspectors(params?: Record<string, unknown>): AxiosPromise<ReportInspectorRow[]>
  getKkm(choiceType: boolean | null | undefined, params?: Record<string, unknown>): AxiosPromise<ReportKkmResponse>
  getKkmByActivity(choiceType: boolean | null | undefined, id: number, params?: {ugnsId?: number, locality?: string; inspector?: string; isKkm?: boolean}): AxiosPromise<ReportKkmByActivityRow[]>
  // getRegime(params?: Record<string, unknown>): AxiosPromise<ReportRegimeRow[]>
  getRegimeUgns(params?: Record<string, unknown>): AxiosPromise<ReportRegimeUgnsRow[]>
  getTerritory(params?: Record<string, unknown>): AxiosPromise<ReportTerritoryRow[]>
  getBusinessType(params?: {ugnsId?: number, activity?: number} & PaginationParams): AxiosPromise<Pageable<ReportBusinessTypeByActivityRow[]>>

  getKkmByNalogpayer(id: number, params?: Record<string, unknown>): AxiosPromise<ReportKkmResponse>

  getExcelActivities(params?: Record<string, unknown>): Promise<void>
  getExcelInspectors(params?: Record<string, unknown>): Promise<void>
  getExcelKkm(choiceType: boolean | null | undefined, params?: Record<string, unknown>): Promise<void>
  getExcelKkmByActivity(choiceType: boolean | null | undefined, id: number, params?: {ugnsId?: number, locality?: string; inspector?: string; isKkm?: boolean}): Promise<void>
  // getExcelRegime(params?: Record<string, unknown>): Promise<void>
  getExcelRegimeUgns(params?: Record<string, unknown>): Promise<void>
  getExcelTerritory(params?: Record<string, unknown>): Promise<void>
  getExcelBusinessType(params?: Record<string, unknown>): Promise<void>
}

const getActivities: ReportApi['getActivities'] = (params = {}) => axios.get('/reports/activitytype', { params });
const getInspectors: ReportApi['getInspectors'] = (params = {}) => axios.get('/reports/inspectors', { params });
const getKkm: ReportApi['getKkm'] = (choiceType, params = {}) => axios.get(`/reports/kkm/${choiceType}`, { params });
const getKkmByActivity: ReportApi['getKkmByActivity'] = (choiceType, activity, params = {}) => axios.get(`/reports/kkm/${choiceType}/${activity}`, { params });
// const getRegime: ReportApi['getRegime'] = (params = {}) => axios.get('/reports/nalogregime', { params });
const getRegimeUgns: ReportApi['getRegimeUgns'] = (params = {}) => axios.get('/reports/nalogregimeugns', { params });
const getTerritory: ReportApi['getTerritory'] = (params = {}) => axios.get('/reports/ugns', { params });
const getBusinessType: ReportApi['getBusinessType'] = (params = {}) => axios.get('/reports/type-object', { params });

const getKkmByNalogpayer: ReportApi['getKkmByNalogpayer'] = (id, params = {}) => axios.get(`/nalogpayer/get/${id}`, { params });

const getExcelActivities: ReportApi['getExcelActivities'] = (params = {}) => axios.get('/excel/reports/activitytype', { params, responseType: 'blob' }).then(downloadFileDynName);
const getExcelInspectors: ReportApi['getExcelInspectors'] = (params = {}) => axios.get('/excel/reports/inspectors', { params, responseType: 'blob' }).then(downloadFileDynName);
const getExcelKkm: ReportApi['getExcelKkm'] = (choiceType, params = {}) => axios.get(`/excel/reports/kkm/${choiceType}`, { params, responseType: 'blob' }).then(downloadFileDynName);
const getExcelKkmByActivity: ReportApi['getExcelKkmByActivity'] = (choiceType, activity, params = {}) => axios.get(`/excel/reports/kkm/${choiceType}/${activity}`, { params, responseType: 'blob' }).then(downloadFileDynName);
// const getExcelRegime: ReportApi['getExcelRegime'] = (params = {}) => axios.get('/excel/reports/nalogregime', { params, responseType: 'blob' }).then(downloadFileDynName);
const getExcelRegimeUgns: ReportApi['getExcelRegimeUgns'] = (params = {}) => axios.get('/excel/reports/nalogregimeugns', { params, responseType: 'blob' }).then(downloadFileDynName);
const getExcelTerritory: ReportApi['getExcelTerritory'] = (params = {}) => axios.get('/excel/reports/ugns', { params, responseType: 'blob' }).then(downloadFileDynName);
const getExcelBusinessType: ReportApi['getExcelBusinessType'] = (params = {}) => axios.get('/excel/reports/type-object', { params, responseType: 'blob' }).then(downloadFileDynName);

const report: ReportApi = {
  getActivities,
  getInspectors,
  getKkm,
  getKkmByActivity,
  getKkmByNalogpayer,
  // getRegime,
  getRegimeUgns,
  getTerritory,
  getBusinessType,
  getExcelActivities,
  getExcelInspectors,
  getExcelKkm,
  getExcelKkmByActivity,
  // getExcelRegime,
  getExcelRegimeUgns,
  getExcelTerritory,
  getExcelBusinessType,

};

export default report;
