import { RouteLocationNormalized } from 'vue-router';

export interface State {
  loggedIn: boolean;
  prevRoute: RouteLocationNormalized | null;
}

export const state: State = {
  loggedIn: false,
  prevRoute: null,
};
