import { GetterTree } from 'vuex';
import { RootState } from '@/store/rootState';
import { State } from './state';

export type Getters = {
  GET_REGISTRY_SEARCH(state: State): (searchInput: keyof State) => string | number;
};

export const getters: GetterTree<State, RootState> & Getters = {
  GET_REGISTRY_SEARCH: (state) => (searchInput) => state[searchInput],
};
