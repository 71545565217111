import { GetterTree } from 'vuex';
import { RootState } from '@/store/rootState';
import { Statistic } from '@/types/Statistic';
import { State } from './state';

export type Getters = {
  getInfo(state: State): () => Statistic | undefined
  getSummaryOfNalogPayers(state: State): number
};

export const getters: GetterTree<State, RootState> & Getters = {
  getInfo: (state) => () => state.data,
  getSummaryOfNalogPayers: (state) => Object.values(state.data.nalogPayersStat)
    .reduce((prev, next) => next + prev, 0),
};
