
import { defineComponent, PropType } from 'vue';
import { Mall, Market } from '@/types/Markets';

export default defineComponent({
  name: 'TOGroupedList',
  props: {
    row: { type: Array as PropType<Mall[] | Market[]> },
    field: { type: String as PropType<keyof (Mall | Market)> },
    tag: { type: String, default: 'ul' },
    link: { type: String, default: null },
    getId: { type: Function },
  },
  setup() {
    const getLabel = <T, K extends keyof T>(OT: T, label: K): T[K] | string => OT[label] || '0';
    return { getLabel };
  },
});
