import { LoadingStatus } from '@/types/Simple';
import { ObjectOfTrade } from '@/types/ObjectOfTrade';

export interface State {
  objects: ObjectOfTrade[] | undefined;
  loadingStatus: LoadingStatus;
}

export const state: State = {
  objects: undefined,
  loadingStatus: 'loading',
};
