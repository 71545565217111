import { GetterTree } from 'vuex';
import { RootState } from '@/store/rootState';
import { State, MapSettings } from './state';

export type Getters = {
  GET_SEARCH(state: State): string;
  GET_MAP_SETTINGS(state: State): MapSettings
};

export const getters: GetterTree<State, RootState> & Getters = {
  GET_SEARCH: (state) => state.currentSearch,
  GET_MAP_SETTINGS: (state) => state.mapSettings,
};
