import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveDynamicComponent as _resolveDynamicComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.row)
    ? (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.tag), { key: 0 }, {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.row, (entry) => {
            return (_openBlock(), _createElementBlock("li", {
              key: entry.id
            }, [
              (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.link ? 'router-link' : 'div'), {
                class: _normalizeClass(_ctx.link ? 'link' : null),
                to: _ctx.link ? { name: _ctx.link, params: _ctx.getId(entry) } : null
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.getLabel(entry, _ctx.field)), 1)
                ]),
                _: 2
              }, 1032, ["class", "to"]))
            ]))
          }), 128))
        ]),
        _: 1
      }))
    : _createCommentVNode("", true)
}