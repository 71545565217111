import { LoadingStatus } from '@/types/Simple';
import { Statistic } from '@/types/Statistic';

export interface State {
  data: Statistic;
  loadingStatus: LoadingStatus;
}

export const state: State = {
  data: {} as Statistic,
  loadingStatus: 'loading',
};
