import { RouteLocationNormalized } from 'vue-router';
import { MutationTree } from 'vuex';
import { State } from './state';

// eslint-disable-next-line no-shadow
export enum MutationTypes {
  SET_LOGGED_IN = 'SET_LOGGED_IN',
  SET_FROM_ROUTE = 'SET_FROM_ROUTE',
}

export type Mutations<S = State> = {
  [MutationTypes.SET_LOGGED_IN](state: S, status: boolean): void;
  [MutationTypes.SET_FROM_ROUTE](state: S, route: RouteLocationNormalized): void;
};

export const mutations: MutationTree<State> & Mutations = {
  [MutationTypes.SET_LOGGED_IN](state, status) {
    state.loggedIn = status;
  },
  [MutationTypes.SET_FROM_ROUTE](state, route) {
    state.prevRoute = route;
  },
};
