<template lang="pug">
UiCheckbox(
  :modelValue="isChecked"
  :disabled="disabled"
)
</template>
<script>

export default {
  props: {
    isChecked: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
