import { MutationTree } from 'vuex';
import { State } from './state';

export type Mutations<S = State> = {
  SET_REGISTRY_SEARCH(state: State, input: { value: string | number, entity: keyof State}): void;

};

export const mutations: MutationTree<State> & Mutations = {
  SET_REGISTRY_SEARCH: (state, input) => {
    state[input.entity] = input.value;
  },
};
