<template lang="pug">
v-select(
  v-model="proxyValue"
  :options="options.data"
  :reduce="options.reduce || (({ value }) => value)"
  :label="options.label || 'label'"
  :searchable="options.searchable ?? true"
  :multiple="options.multiple ?? true"
  :placeholder="options.placeholder || 'Введите'"
  :closeOnSelect="options.closeOnSelect ?? true"
  @option:selecting="handleSelect"
)
  //- сообщение, если нет результатов поиска
  template(#no-options="{ search, searching }")
    template(v-if="search") Нет результатов для "{{ search }}"

  //- настройка выбранных опций
  template(#selected-option="{ label, value }")
    template(v-if="proxyValue?.length > 1 && proxyValue[0] == value")
      span(style="position:relative") {{ label }}
        span.marker
        span.count &nbsp;+{{ proxyValue?.length - 1 }}

  //- настройка опций в выпадающем списке
  template(#option="{ label, value }")
    div(style="display:flex;column-gap:15px;align-items:flex-end;")
      UiCheckbox(:modelValue="isChecked({ value, label })")
      span {{ label }}

  //- отображать только первую выбранную опцию, если включен мультиселект
  template(#selected-option-container="{ option, multiple }")
    div(v-if="proxyValue?.length > 1 && proxyValue[0] != option.value && multiple" style="display:none")

  //- сдвигать инпут поиска при появлении маркера с количеством выбранных опций
  template(#search="{ attributes, events }")
      input(
        style="position:relative"
        :style="{right: proxyValue?.length > 1 ? '-20px': ''}"
        class="vs__search"
        v-bind="attributes"
        v-on="events"
      )
</template>
<script>
import { defineComponent } from 'vue';
import VSelect from 'vue-select';

export default defineComponent({
  name: 'UiMultiSelector',
  props: {
    modelValue: {
      type: [String, Number, Array],
    },
    options: {
      type: [Object],
    },
  },
  components: {
    VSelect,
  },
  methods: {
    isChecked(entities) {
      const entity = this.options.entityForCB || 'value';
      if (Number.isInteger(this.proxyValue)) return this.proxyValue === entities[entity];
      return this.proxyValue?.includes(entities[entity]);
    },
    handleSelect({ value: selectedValue }) {
      if (Array.isArray(this.proxyValue) && this.proxyValue.includes(selectedValue)) {
        if (this.options.multiple !== false) {
          this.proxyValue = this.proxyValue.filter((optionValue) => selectedValue !== optionValue);
        } else {
          this.proxyValue = [];
        }
      }
    },
  },
  computed: {
    proxyValue: {
      get() {
        return this.modelValue;
      },
      set(newValue) {
        this.$emit('update:modelValue', newValue ?? []);
      },
    },
  },
});
</script>
<style lang="stylus" scoped>
span.marker
  width 20px
  height 20px
  background #028a8f
  border-radius 50%
  position absolute
  right -40px
  top -1px
span.count
  position absolute
  right -37.5px
  color #ffffff
</style>
