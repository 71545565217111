
import {
  computed,
  defineComponent,
  HTMLAttributes,
  PropType,
  StyleHTMLAttributes,
  toRefs,
  ComputedRef, onMounted, ref, onBeforeUnmount,
} from 'vue';
import searchIcon from '@/assets/images/ic-search.svg';
import clearIcon from '@/assets/images/ic-clear.svg';

export default defineComponent({
  name: 'UiInput',
  props: {
    type: { default: 'text', type: String as PropType<HTMLInputElement['type']> },
    width: { default: '', type: [String, Number] },
    style: { default: () => ({}), type: Object as PropType<StyleHTMLAttributes> },
    modelValue: { required: false, default: '', type: String },
    icon: { default: '', type: String },
    label: { default: '', type: [String, Number] as PropType<string | number> },
    placeholder: { default: '', type: String },
    name: { default: '', type: String },
    search: { default: false, type: Boolean },
    disabled: { default: false, type: Boolean },
    flat: { default: false, type: Boolean },
    inputId: { default: '', type: String },
    useOptions: { default: false, type: Boolean },
    options: { required: false, type: Object } as unknown as PropType<{rows: {name: string, value: string}[], display: boolean} | void>,
    clear: { default: false, type: Boolean },
  },
  setup(props) {
    const { width } = toRefs(props);

    const computedStyle: ComputedRef<HTMLAttributes['style']> = computed((): HTMLAttributes['style'] => {
      const styleObj: HTMLAttributes['style'] = {};
      if (width.value) {
        styleObj.width = width.value;
      }
      return styleObj;
    });

    const computedClass: ComputedRef<HTMLAttributes['class']> = computed(() => {
      const classStr = [] as string[];
      if (props.icon) {
        classStr.push('ui-input--with-icon');
      }
      if (props.flat) {
        classStr.push('ui-input--flat');
      }
      return classStr.join(' ');
    });

    // eslint-disable-next-line vue/no-setup-props-destructure
    const options$ = props.options;
    const refRoot = ref<HTMLDivElement | null>(null);
    const refInput = ref<HTMLInputElement | null>(null);

    function hideIfOutside() {
      if (options$) options$.display = false;
    }

    onMounted(() => {
      document.body.addEventListener('click', hideIfOutside);
    });
    onBeforeUnmount(() => {
      document.body.removeEventListener('click', hideIfOutside);
    });

    function onFocus() {
      if (!options$) return;
      options$.display = true;
      const el = refInput.value as HTMLInputElement;
      setTimeout(() => {
        el.setSelectionRange(el.value.length, el.value.length);
      });
    }

    return {
      refRoot,
      refInput,
      options$,
      computedStyle,
      computedClass,
      searchIcon,
      clearIcon,
      onFocus,
    };
  },
});
