import { ActionContext, ActionTree } from 'vuex';
import { RootState } from '@/store/rootState';
import api from '@/api';
import { State } from './state';
import { MutationTypes } from './mutations';

export interface Actions {
  GET_DATA(
    { commit }: ActionContext<State, RootState>,
  ): void;
}

export const actions: ActionTree<State, RootState> & Actions = {
  async GET_DATA({ commit }: ActionContext<State, RootState>): Promise<void> {
    commit(MutationTypes.SET_STATUS, 'loading');
    try {
      const resp = await api.statistic.getInfo();
      commit(MutationTypes.SET_DATA, resp.data);
      commit(MutationTypes.SET_STATUS, 'success');
    } catch (e) {
      commit(MutationTypes.SET_STATUS, 'error');
    }
  },
};
