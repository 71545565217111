import { AxiosPromise } from 'axios';
import axios from '@/axios';
import { InnerProblemDto } from '@/types/Map';

export interface InnerProblemApi {
  saveProblemOnTheMap(type: InnerProblemDto): AxiosPromise<void>
}

const saveProblemOnTheMap: InnerProblemApi['saveProblemOnTheMap'] = (params) => axios.post('/inner-problem', params);
const innerProblem: InnerProblemApi = {
  saveProblemOnTheMap,
};

export default innerProblem;
