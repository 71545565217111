export type MapSettings = { coords: [ number, number ], zoom: number }

export interface State {
  currentSearch: string;
  mapSettings: MapSettings
}

export const state: State = {
  currentSearch: '',
  mapSettings: { coords: [42.869125, 74.5567644], zoom: 15 },
};
