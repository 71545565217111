import { AxiosPromise } from 'axios';
import { Worker } from '@/types/Ugnstno';
import { TradeObjectPreview } from '@/types/ObjectOfTrade';
import { IObjectCount, StatusStat } from '@/types/Statistic';
import { Pageable, PaginationParams } from '@/types/Common';
import { UgnsTnoWorker } from '@/types/UgnsTnoById';
import { RoleSelector } from '@/types/RoleType';
import axios from '../axios';

export interface UserApi {
  login({ username, password }: { username: string, password: string }): AxiosPromise<{
    token: string;
    refreshToken?: string;
  }>
  info(): Promise<Worker>,
  infoById(id: number): AxiosPromise<Worker>,
  getWorkerById(id: number): AxiosPromise<UgnsTnoWorker>,
  findAll(params?: PaginationParams): AxiosPromise<Pageable<Worker>>,
  loadfile(file: File): AxiosPromise<void>,
  downloadTemplate(): Promise<void>,
  getUserTradeObjects(id: number, params?: {address?: string} & PaginationParams): AxiosPromise<Pageable<TradeObjectPreview>>
  getUserStats(id: number): AxiosPromise<StatusStat>
  logout(): AxiosPromise<void>,
  refresh():AxiosPromise<{ token: string }>,
  roles(): AxiosPromise<RoleSelector[]>,
  updateRoles(id: number, params: Record<string, any>): AxiosPromise<Worker>,
  countObjects(id: number): AxiosPromise<IObjectCount>,
  deleteUsers(params: { removeUserId: number | null, delegatedUserId: number | null }): AxiosPromise<void>,
}

const login: UserApi['login'] = (credentials) => axios.post('/users/signin', credentials);
const info: UserApi['info'] = () => axios.get('/users/me').then(({ data }) => data);
const infoById: UserApi['infoById'] = (id) => axios.get(`/user/${id}/info`);
// const getWorkerById: UserApi['getWorkerById'] = (id) => axios.get(`/objectworkers/${id}`);
const getWorkerById: UserApi['getWorkerById'] = (id) => axios.get(`/users/${id}/objectworkers`);
const findAll: UserApi['findAll'] = (params) => axios.get('/users/findAll', { params });
const loadfile: UserApi['loadfile'] = (file) => {
  const formData = new FormData();
  formData.append('file', file);
  return axios.post('/users/loadfile', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};
const logout: UserApi['logout'] = () => axios.post('/users/logout');
const refresh: UserApi['refresh'] = () => axios.get('/users/refresh');
const downloadTemplate: UserApi['downloadTemplate'] = async () => {
  const response = await axios.get('/template/getfile?template=USERS', { responseType: 'blob' });
  const url = window.URL.createObjectURL(new Blob([response.data]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', 'Шаблон загрузки пользователей.xlsx');
  document.body.appendChild(link);
  link.click();
};
const getUserTradeObjects: UserApi['getUserTradeObjects'] = (id, params) => axios.get(`/users/${id}/tradeobjects`, { params });
const getUserStats: UserApi['getUserStats'] = (id) => axios.get(`/users/${id}/stats`);
const roles: UserApi['roles'] = () => axios.get('/users/roles');
const updateRoles: UserApi['updateRoles'] = (id, params) => axios.put(`/users/${id}`, params);
const countObjects: UserApi['countObjects'] = (id) => axios.get(`/users/${id}/objects`);
const deleteUsers: UserApi['deleteUsers'] = (params) => axios.delete('/users', { params });

const user: UserApi = {
  login,
  info,
  infoById,
  getWorkerById,
  findAll,
  loadfile,
  downloadTemplate,
  getUserTradeObjects,
  getUserStats,
  logout,
  refresh,
  roles,
  updateRoles,
  countObjects,
  deleteUsers,
};

export default user;
