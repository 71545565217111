import { AxiosPromise } from 'axios';
import { TaxPayer } from '@/types/ObjectOfTrade';
import { NalogPayer } from '@/types/NalogPayer';
import { Pageable, PaginationParams } from '@/types/Common';
import axios from '../axios';

export interface TaxPayersApi {
  findAll(params?: {inspector?: number, objectid?: string, nameorinn?: string} & PaginationParams): AxiosPromise<Pageable<TaxPayer>>
  getById(id: string | string[]): AxiosPromise<TaxPayer>
  getQRCodeById(id: string): Promise<string>
  saveById(id: number, body: Record<string, unknown>): AxiosPromise<void>
  getPhotoId(id: string): Promise<string>
  loadPhotoId(id: string | string[], photo: FormData): AxiosPromise<void>
  assignWorkerToTaxPayer(id: string | number, body: number[]): AxiosPromise<void>
  getAll(params: { duplicate: boolean, pageSize: number, page: number }): AxiosPromise<Pageable<Partial<NalogPayer>>>
}

const findAll: TaxPayersApi['findAll'] = (params = {}) => axios.get('/nalogpayer/findall', { params });

const getById: TaxPayersApi['getById'] = (id) => axios.get(`/nalogpayer/get/${id}`);
const saveById: TaxPayersApi['saveById'] = (id, body = {}) => axios.put(`/nalogpayer/${id}`, body);

const getQRCodeById: TaxPayersApi['getQRCodeById'] = (id) => axios.get(`/nalogpayer/getqrcode/${id}`, {
  responseType: 'blob',
}).then((res) => {
  const { data } = res;
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.readAsDataURL(data);
    reader.onload = (e) => {
      if (e.target?.result && typeof e.target.result === 'string') {
        resolve(e.target?.result);
      } else {
        resolve('');
      }
    };
  });
});

const getPhotoId: TaxPayersApi['getPhotoId'] = (id) => axios.get(`/nalogpayer/getphoto/${id}`, {
  responseType: 'blob',
}).then((res) => {
  const { data } = res;
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.readAsDataURL(data);
    reader.onload = (e) => {
      // const img = new Image()
      // img.src = e.target.result
      // document.body.appendChild(img)
      if (e.target?.result && typeof e.target.result === 'string') {
        resolve(e.target?.result);
      } else {
        resolve('');
      }
    };
  });
});

const loadPhotoId: TaxPayersApi['loadPhotoId'] = (id, photo) => axios.post(`/nalogpayer/loadphoto/${id}`, photo);

const assignWorkerToTaxPayer: TaxPayersApi['assignWorkerToTaxPayer'] = (id, body) => axios.put(`/nalogpayer/${id}/inspector`, body);

const getAll: TaxPayersApi['getAll'] = (params) => axios.get('/nalogpayer', { params });

const objectsOfTrade: TaxPayersApi = {
  findAll,
  getById,
  getQRCodeById,
  saveById,
  getPhotoId,
  loadPhotoId,
  assignWorkerToTaxPayer,
  getAll,
};

export default objectsOfTrade;
