import { AxiosPromise } from 'axios';
import { Type } from '@/types/ObjectOfTrade';
import axios from '../axios';

export interface KkmModelApi {
  findAll(): AxiosPromise<Type[]>
  loadFile(id: FormData): AxiosPromise<void>
  update(type: Type): AxiosPromise<void>
}

const findAll: KkmModelApi['findAll'] = () => axios.get('/kkmmodel/findall');

const loadFile: KkmModelApi['loadFile'] = (file) => axios.post('/kkmmodel/loadfile', file);

const update: KkmModelApi['update'] = (type) => axios.post('/kkmmodel/save', type);

const kkmModel: KkmModelApi = {
  findAll,
  loadFile,
  update,
};

export default kkmModel;
