import { AxiosPromise } from 'axios';
import axios from '@/axios';
import { Pageable, PaginationParams } from '@/types/Common';
import { Mall, MallCreate, MallTaxpayer } from '@/types/Markets';
import { MarketsApi } from '@/api/markets';

export interface MallsApi {
  findAll(params?: { ugnsId?: number, web?: boolean }): AxiosPromise<Record<string, any>[]>
  getById(id: number): AxiosPromise<Mall>
  getTaxpayers(id: number, params?: Record<string, unknown>): AxiosPromise<Pageable<MallTaxpayer>>
  updateById(id: string | string[], file: File): AxiosPromise<Record<string, unknown>>
  create(data: MallCreate): AxiosPromise<Record<string, unknown>>
  createByFile(data: File): AxiosPromise<Record<string, unknown>>
  downloadTemplate(): Promise<void>
  deleteMall(id: number): Promise<void>
  updateMall(data: Mall): Promise<void>
}

const findAll: MallsApi['findAll'] = (params) => axios.get('/shoppingmalls', { params });
const getById: MallsApi['getById'] = (id) => axios.get(`/shoppingmalls/${id}`);
const getTaxpayers: MallsApi['getTaxpayers'] = (id, params) => axios.get(`/shoppingmalls/${id}/taxpayers`, { params });
const updateById: MallsApi['updateById'] = (id) => axios.put(`/shoppingmalls/${id}`);
const create: MallsApi['create'] = (data) => axios.post('/shoppingmalls/loadfile', data);
const createByFile: MarketsApi['createByFile'] = (file) => {
  const formData = new FormData();
  formData.append('file', file);
  return axios.post('/shoppingmalls/loadfile', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};
const downloadTemplate: MallsApi['downloadTemplate'] = async () => {
  const response = await axios.get('/template/getfile?template=MALL', { responseType: 'blob' });
  const url = window.URL.createObjectURL(new Blob([response.data]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', 'Шаблон загрузки торговых центров.xlsx');
  document.body.appendChild(link);
  link.click();
};
const deleteMall: MallsApi['deleteMall'] = (id) => axios.delete(`/shoppingmalls/${id}`);
const updateMall: MallsApi['updateMall'] = (data) => axios.put(`/shoppingmalls/${data.id}`, data);

const malls: MallsApi = {
  findAll,
  getById,
  getTaxpayers,
  updateById,
  create,
  createByFile,
  downloadTemplate,
  deleteMall,
  updateMall,
};

export default malls;
