<template lang="pug">
img(v-if="liquidated" src="@/assets/images/ic-liquidate-marker.svg")
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    liquidated: { type: Boolean, default: false },
  },
});
</script>
