export interface State {
  tradeObjectSearch: string | number;
  taxPayersSearch: string | number;
  nalogPayersSearch: string | number;
}

export const state: State = {
  tradeObjectSearch: '',
  taxPayersSearch: '',
  nalogPayersSearch: '',
};
