import { MutationTree } from 'vuex';
import { State, MapSettings } from './state';

export type Mutations<S = State> = {
  SET_SEARCH(state: S, value: string): void;
  SET_MAP_SETTINGS(state: S, value: MapSettings): void;
};

export const mutations: MutationTree<State> & Mutations = {
  SET_SEARCH(state, value) {
    state.currentSearch = value;
  },
  SET_MAP_SETTINGS: (state, value) => {
    state.mapSettings = value;
  },
};
