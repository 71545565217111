import { GetterTree } from 'vuex';
import { RootState } from '@/store/rootState';
import { UgnsTno } from '@/types/Ugnstno';
import { State } from './state';

export type Getters = {
  getObjectById(state: State): (id: string) => UgnsTno | undefined
};

export const getters: GetterTree<State, RootState> & Getters = {
  getObjectById: (state) => (id: string) => state.objects?.find((elem) => elem.id.toString() === id),
};
