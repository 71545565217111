import { LoadingStatus } from '@/types/Simple';
import { TaxPayer } from '@/types/ObjectOfTrade';

export interface State {
  objects: TaxPayer[] | null;
  loadingStatus: LoadingStatus;
}

export const state: State = {
  objects: null,
  loadingStatus: 'loading',
};
