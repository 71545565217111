import { ActionContext, ActionTree } from 'vuex';
import { RootState } from '@/store/rootState';
import api from '@/api';
import { State } from './state';
import { MutationTypes } from './mutations';

export interface Actions {
  LOGIN(
    ctx: ActionContext<State, RootState>,
    { username, password }: { username: string, password: string }
  ): Promise<void>;
  LOGOUT(ctx: ActionContext<State, RootState>): void;
  CLEAR_TOKENS(ctx: ActionContext<State, RootState>): void;
  SET_TOKENS(
    ctx: ActionContext<State, RootState>,
    { token }: {token: string }
  ): void;
  CHECK_TOKENS_EXIST(
    ctx: ActionContext<State, RootState>
  ): void;
}

export const actions: ActionTree<State, RootState> & Actions = {
  async LOGIN({ dispatch }, { username, password }) {
    const resp = await api.user.login({ username, password });
    const { token } = resp.data;
    await dispatch('SET_TOKENS', { token });
  },
  SET_TOKENS(_, { token = localStorage.getItem('token') || '' }: { token?: string} = {}) {
    localStorage.setItem('token', token || '');
  },
  CHECK_TOKENS_EXIST() {
    const token = localStorage.getItem('token');
    return !!(token);
  },
  async CLEAR_TOKENS() {
    localStorage.removeItem('token');
  },
  async LOGOUT({ dispatch }) {
    await api.user.logout();
    await dispatch('CLEAR_TOKENS');
  },
  async GET_USER_INFO({ commit }: ActionContext<State, RootState>): Promise<void> {
    const worker = (await api.user.info());
    commit(MutationTypes.SET_USER_INFO, worker);
  },
};
