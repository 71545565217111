import { AxiosPromise } from 'axios';
import { BusinessType } from '@/types/BusinessType';
import axios from '../axios';

export interface BusinessTypeApi {
  findAll(): AxiosPromise<BusinessType[]>
  loadFile(id: FormData): AxiosPromise<void>
  update(type: BusinessType): AxiosPromise<void>
}

const findAll: BusinessTypeApi['findAll'] = () => axios.get('/businesstype/findall');

const loadFile: BusinessTypeApi['loadFile'] = (file) => axios.post('/businesstype/loadfile', file);

const update: BusinessTypeApi['update'] = (type) => axios.post('/businesstype/save', type);

const businessType: BusinessTypeApi = {
  findAll,
  loadFile,
  update,
};

export default businessType;
