import { UgnsTno } from '@/types/Ugnstno';
import { LoadingStatus } from '@/types/Simple';

export interface State {
  objects: UgnsTno[] | null;
  loadingStatus: LoadingStatus;
}

export const state: State = {
  objects: null,
  loadingStatus: 'loading',
};
