import { ActionContext, ActionTree } from 'vuex';
import { RootState } from '@/store/rootState';
import api from '@/api';
import { State } from './state';
import { MutationTypes } from './mutations';

export interface Actions {
  login(
    { commit }: ActionContext<State, RootState>,
    { username, password }: { username: string, password: string }
  ): void;
}

export const actions: ActionTree<State, RootState> & Actions = {
  async login({ commit }: ActionContext<State, RootState>, { username, password }): Promise<void> {
    try {
      await api.user.login({ username, password });
      commit(MutationTypes.SET_LOGGED_IN, true);
    } catch (e) {
      commit(MutationTypes.SET_LOGGED_IN, false);
    }
  },
};
