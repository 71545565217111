import { MutationTree } from 'vuex';
import { Worker } from '@/types/Ugnstno';
import { State } from './state';

// eslint-disable-next-line no-shadow
export enum MutationTypes {
  LOGIN = 'LOGIN',
  SUCCESS = 'SUCCESS',
  ERROR = 'ERROR',
  LOGOUT = 'LOGOUT',
  SET_USER_INFO = 'SET_USER_INFO',
}

export type Mutations<S = State> = {
  [MutationTypes.SET_USER_INFO](state: S, worker: Worker): void;
};

export const mutations: MutationTree<State> & Mutations = {
  [MutationTypes.SET_USER_INFO](state, worker: Worker) {
    state.userInfo = worker;
  },
};
