
import { defineComponent, PropType } from 'vue';
import Paginate from '@hennge/vue3-pagination';
import { TableCol } from '@/components/UI/Table/types';
import RolesCheckbox from '@/views/admin-panel/components/RolesCheckbox.vue';
import TOGroupedList from '@/views/markets-and-malls/components/TOGroupedList.vue';
import ChangeStatus from './components/ChangeStatus.vue';
import Liquidated from './components/Liquidated.vue';
import RemoveBtn from './components/RemoveBtn.vue';
import EditBtn from './components/EditBtn.vue';

export default defineComponent({
  name: 'UiTable',
  components: {
    ChangeStatus,
    RolesCheckbox,
    Liquidated,
    TOGroupedList,
    Paginate,
    RemoveBtn,
    EditBtn,
  },
  props: {
    cols: { type: Array as PropType<TableCol[]>, default: () => [] },
    rows: { type: Array, default: () => [] },
    emptyBannerText: { type: String, default: 'Список пуст', required: false },
    useCheckboxes: { type: Boolean, default: false },
    showIndex: { type: Boolean, default: false },
    selectedRows: { type: Array, default: () => [] },
    options: { type: Object },
    pagination: { type: Object as PropType<{ pages: number, currentPage: number, fetching: boolean }>, default: null },
  },
  emits: ['rowClick', 'update:selectedRows', 'change:columnSlideIndex', 'change:role', 'changePage', 'change:ugns', 'removeUser', 'editUser'],
});
