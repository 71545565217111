import { GetterTree } from 'vuex';
import { RootState } from '@/store/rootState';
import { TaxPayer } from '@/types/ObjectOfTrade';
import { State } from './state';

export type Getters = {
  getObjectById(state: State): (id: string) => TaxPayer | undefined
  getTaxPayerByName(state: State): (name: string) => TaxPayer | undefined
};

export const getters: GetterTree<State, RootState> & Getters = {
  getObjectById: (state) => (id: string) => state.objects?.find((elem) => elem.id.toString() === id),
  getTaxPayerByName: (state) => (name: string) => state.objects?.find((elem) => elem.objectName?.toLowerCase() === name.toLowerCase()),
};
